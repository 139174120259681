<template>
  <div class="content-wrapper dashboard px-2">
    <h4 class="my-4">
      Bem Vindo,
      <strong v-if="user && user.full_name"> {{ user.full_name }}! </strong>
    </h4>
    <Cards :dashboard="dashboard" />
    <div class="d-md-flex my-3 min-h-61 justify-content-between gap-3">
      <b-card class="p-0 col-md-8">
        <section class="d-flex align-items-center justify-content-between mb-2">
          <h6 class="mb-0">Últimos serviços</h6>
          <b-button variant="link" class="px-0" to="/service/list">
            Ver todos
          </b-button>
        </section>
        <list-services />
      </b-card>
      <b-card class="col-md-4 p-0">
        <section class="d-flex align-items-center justify-content-between mb-2">
          <h6 class="mb-0">Usuários</h6>
          <b-button variant="link" class="px-0" to="/users/list">
            Ver todos
          </b-button>
        </section>
        <list-users />
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListServices from "./list-services.vue";
import ListUsers from "./list-users.vue";

import Cards from "./cards";

export default {
  components: {
    Cards,
    ListServices,
    ListUsers,
  },

  data() {
    return {
      dashboard: {},
      series: [44, 55, 13, 43],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
          stroke: {
            show: false,
          },
        },
        labels: ["Team A", "Team B", "Team C", "Team D"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["get_dashboard", "hide_application_header"]),
  },
  mounted() {
    this.hide_application_header(false);
    this.get_dashboard().then(({ data }) => {
      this.dashboard = data;
    });
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
};
</script>
<style lang="scss">
.dashboard {
  .col-md-8 {
    flex: 1;
  }
  .col-md-4 {
    flex: 1;
  }
}
</style>
